<template>
  <SdPage>
    <SdPageHeader
      :back-route="{ name: 'app.properties' }"
      title="Add property"
    />

    <SdLoadingLayer :init="init">
      <template v-slot:loaded>
        <div class="col-12 col-md-7">
          <ElForm
            ref="form"
            :model="payloadRef"
            :rules="rulesRef"
            class="row mt-3 align-items-center"
            @submit.native.prevent="addProperty"
          >
            <PropertyMainDetails
              ref="form-main-details"
              :property="payloadRef"
              :is-invalid-property-address="isInvalidPropertyAddress"
              :has-appfolio-new-integration="hasAppFolioNewIntgration"
              :suggested-property-address="suggestedPropertyAddress"
              :property-with-same-address-ref="propertyWithSameAddressRef"
              :is-add-property-form="true"
              :business-source="businessSourceRef"
              @update-property-payload="updatePropertyPayload"
            />
            <ElButton
              class="mt-4"
              type="primary"
              size="medium"
              :loading="formState.loading"
              :disabled="!!propertyWithSameAddressRef || isInvalidPropertyAddress"
              @click.native="addProperty"
            >
              Next
            </ElButton>
          </ElForm>
        </div>
      </template>
    </SdLoadingLayer>
  </SdPage>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';
import get from 'lodash.get';
import has from 'lodash.has';
import * as Sentry from '@/utils/SentryUtil';
import { BusinessSourceTypes } from '@/constants/BusinessSource';
import { PropertyType } from '@/constants/Property';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import PropertyMainDetails, { propertyDetailsRules } from '../../components/property/PropertyMainDetails';

export default {
  name: 'PropertyAdd',
  components: {
    PropertyMainDetails,
  },
  setup(props, context) {
    const store = context.root.$store;
    const dispatch = context.root.$store.dispatch;
    const payloadRef = ref({ ...defaultPayload });
    const rulesRef = ref(propertyDetailsRules);
    const isInvalidPropertyAddress = ref(false);
    const suggestedPropertyAddress = ref(null);
    const propertyWithSameAddressRef = ref(null);
    const formState = reactive(defaultFormState);
    const hasAppFolioNewIntgration = ref(false);
    const businessSourceRef = ref({});

    return {
      payloadRef,
      rulesRef,
      isInvalidPropertyAddress,
      hasAppFolioNewIntgration,
      suggestedPropertyAddress,
      propertyWithSameAddressRef,
      formState,
      addProperty,
      updatePropertyPayload,
      init,
      businessSourceRef,
    };

    async function init() {
      await fetchBusinessSource();
    }

    async function fetchBusinessSource() {
      try {
        businessSourceRef.value = await store.dispatch('BusinessSource/index');
        hasAppFolioNewIntgration.value = !!businessSourceRef.value.client_id && businessSourceRef.value.source_type === BusinessSourceTypes.APPFOLIO;
      } catch (e) {
        businessSourceRef.value = {};
      }
    }

    /**
     * Update payload when child components fields were changed.
     */
    async function updatePropertyPayload(componentFields) {
      const fields = componentFields.value ? componentFields.value : componentFields;
      Object.keys(fields).forEach((key) => {
        if (key in payloadRef.value) {
          payloadRef.value[key] = fields[key];
        }
      });

      if (has(fields, 'address.data')) {
        isInvalidPropertyAddress.value = !get(fields, 'address.data.vicinity');

        const possibleSuggestion = get(fields, 'address.value');

        if (isInvalidPropertyAddress.value) {
          // only suggest if suggestion is different and contains a number(except ending with a number)
          if (isInvalidPropertyAddress.value !== possibleSuggestion && /.*\d.*[^\d]$/.test(possibleSuggestion)) {
            suggestedPropertyAddress.value = possibleSuggestion;
          } else {
            suggestedPropertyAddress.value = null;
          }

          Sentry.captureException(new Error('Trying to add address without a vicinity'), {
            tags: {
              suggested_address: suggestedPropertyAddress.value ? suggestedPropertyAddress.value : 'no suggestion was shown to user',
            },
          });
        }
      }

      const geoPoint = get(fields, 'address.geo_point');

      const addressValue = get(fields, 'address.value');

      if (addressValue) {
        formState.loading = true;
        const propertiesWithTheSameAddress = await context.root.$store.dispatch('Property/get', {
          existing_address_query: addressValue,
        });
        propertyWithSameAddressRef.value = propertiesWithTheSameAddress.properties.data[0] ?? null;
        formState.loading = false;
      } else {
        propertyWithSameAddressRef.value = null;
      }
    }

    async function addProperty() {
      if (propertyWithSameAddressRef.value) {
        return;
      }

      formState.loading = true;

      try {
        await context.refs.form.validate();
        await context.refs?.['form-main-details']?.uploadUnitMediaGalleryImages?.();
        if (payloadRef.value.picture && payloadRef.value.picture.size) {
          const formData = new FormData();
          formData.append('file', payloadRef.value.picture);
          const response = await dispatch('Property/uploadPicture', formData);
          updatePropertyPayload({ picture_url: response.url, picture: response.name });
        }

        const property = await context.root.$store.dispatch('Property/addProperty', payloadRef.value);

        formState.loading = false;

        showSuccessNotify(context, 'Property added successfully');

        context.root.$router.push(getRedirectRoute(property));
      } catch (error) {
        formState.loading = false;

        if (!(error instanceof Error)) {
          return; // Validator failed
        }

        showErrorNotify(context, error.message);
      }
    }

    function getRedirectRoute(property) {
      const isMultiUnits = property.units.length > 1;

      if (context.root.$store.getters['Auth/accountIsInactive']) {
        return {
          name: isMultiUnits ? 'app.units' : 'app.units.show',
          params: {
            id: property.id,
            unit_id: !isMultiUnits ? property.units[0].id : undefined,
          },
        };
      }

      return {
        name: 'app.units.services',
        params: {
          id: property.id,
          unit_id: !isMultiUnits ? property.units[0].id : undefined,
        },
        query: {
          address: property.address.value,
          unit_name: !isMultiUnits ? property.units[0].name : undefined,
        },
      };
    }
  },
};

const defaultPayload = {
  listing_manager_id: null,
  address: null,
  pms_code: '',
  picture: '',
  picture_url: '',
  units: [],
  property_type: PropertyType.SingleFamilyHome,
};

const defaultFormState = {
  loading: false,
};
</script>
